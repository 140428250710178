// Compatible with @progress/kendo-theme-bootstrap v.6.4.0

$tb-kendo-is-dark-theme: true;
$tb-kendo-border-radius: 0.25rem;
$tb-kendo-color-primary: #f15a22;
$tb-kendo-color-secondary: #444;
$tb-kendo-color-success: #00bc8c;
$tb-kendo-color-info: #3498db;
$tb-kendo-color-warning: #f39c12;
$tb-kendo-color-danger: #e74c3c;
$tb-kendo-body-bg: #222;
$tb-kendo-body-text: #ffffff;
$tb-kendo-subtle-text: #cccccc;
$tb-kendo-disabled-text: #888;
$tb-kendo-component-bg: #222;
$tb-kendo-base-bg: #292b2c;
$tb-kendo-hover-bg: #eeeff0;
$tb-kendo-hover-text: #1eb97b;
$tb-kendo-selected-text: #ffffff;
$tb-kendo-button-bg: #444;
$tb-kendo-button-text: #ffffff;
$tb-kendo-link-hover-text: #0c9587;
$tb-kendo-series-a: #34d293;
$tb-kendo-series-b: #ffd246;
$tb-kendo-series-c: #78d237;
$tb-kendo-series-d: #28b4c8;
$tb-kendo-series-e: #2d73f5;
$tb-kendo-series-f: #aa46be;
$tb-kendo-component-text: $tb-kendo-body-text;
$tb-kendo-base-text: $tb-kendo-body-text;
$tb-kendo-selected-bg: $tb-kendo-color-primary;
$tb-kendo-link-text: $tb-kendo-color-primary;

$kendo-is-dark-theme: $tb-kendo-is-dark-theme;
$kendo-border-radius: $tb-kendo-border-radius;
$kendo-color-primary: $tb-kendo-color-primary;
$kendo-color-secondary: $tb-kendo-color-secondary;
$kendo-color-success: $tb-kendo-color-success;
$kendo-color-info: $tb-kendo-color-info;
$kendo-color-warning: $tb-kendo-color-warning;
$kendo-color-danger: $tb-kendo-color-danger;
$kendo-body-bg: $tb-kendo-body-bg;
$kendo-body-text: $tb-kendo-body-text;
$kendo-subtle-text: $tb-kendo-subtle-text;
$kendo-disabled-text: $tb-kendo-disabled-text;
$kendo-component-bg: $tb-kendo-component-bg;
$kendo-base-bg: $tb-kendo-base-bg;
$kendo-hover-bg: $tb-kendo-hover-bg;
$kendo-hover-text: $tb-kendo-hover-text;
$kendo-selected-text: $tb-kendo-selected-text;
$kendo-button-bg: $tb-kendo-button-bg;
$kendo-button-text: $tb-kendo-button-text;
$kendo-link-hover-text: $tb-kendo-link-hover-text;
$kendo-series-a: $tb-kendo-series-a;
$kendo-series-b: $tb-kendo-series-b;
$kendo-series-c: $tb-kendo-series-c;
$kendo-series-d: $tb-kendo-series-d;
$kendo-series-e: $tb-kendo-series-e;
$kendo-series-f: $tb-kendo-series-f;
$kendo-component-text: $tb-kendo-component-text;
$kendo-base-text: $tb-kendo-base-text;
$kendo-selected-bg: $tb-kendo-selected-bg;
$kendo-link-text: $tb-kendo-link-text;
$kendo-font-family: system-ui, -apple-system, "Segoe UI", Roboto,
  "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$kendo-font-size: 1rem;
$kendo-font-weight-normal: 400;
$kendo-line-height: 1.5;

$enable-gradients: false;

$kendo-grid-border: rgba(255, 255, 255, 0.123);

$tb-typography: (
  kendo-default-typography: (
    font-family:
      'system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    font-size: 1rem,
    font-weight: 400,
    line-height: 1.5,
  ),
);

@mixin typography-classes($typography) {
  @each $selector, $property in $typography {
    &-#{$selector} {
      @each $propName, $propValue in $property {
        #{$propName}: #{$propValue};
      }
    }
  }
}

$tb-effects: (
  tb-internal-none-effects: (
    box-shadow: (
      none,
    ),
    filter: (
      none,
    ),
    backdrop-filter: (
      none,
    ),
  ),
);

@mixin effects-classes($effects) {
  @each $selector, $property in $effects {
    &-#{$selector} {
      @each $propName, $propValue in $property {
        #{$propName}: $propValue;
      }
    }
  }
}
