//
// Color system
//

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #ebebeb !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #888 !default;
$gray-700: #444 !default;
$gray-800: #303030 !default;
$gray-900: #222 !default;
$black: #000 !default;

$blue: #375a7f !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #e74c3c !default;
$orange: #fd7e14 !default;
$yellow: #f39c12 !default;
$green: #00bc8c !default;
$teal: #20c997 !default;
$cyan: #3498db !default;

$primary: $blue !default;
$secondary: $gray-700 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-500 !default;
$dark: $gray-800 !default;

$secondary-bg: #afafaf !default;
$input-disabled-bg: #afafaf !default;

// Body

$body-bg: $tb-kendo-body-bg !default;
$body-color: $tb-kendo-body-text !default;

// accordion

$accordion-bg: $gray-700 !default;
$accordion-color: $tb-kendo-body-text !default;
$accordion-active-bg: $gray-700 !default;
$accordion-button-color: $tb-kendo-body-text !default;
$accordion-button-bg: $gray-700 !default;
$accordion-button-active-bg: $gray-700 !default;
$accordion-button-active-color: $tb-kendo-body-text !default;
$accordion-border-color: $gray-700 !default;

// button

$btn-color: $white !default;

// Links

$link-color: $success !default;

// Fonts

$font-size-base: 0.9375rem;

// stylelint-disable-next-line value-keyword-case
$font-family-sans-serif: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol" !default;
$h1-font-size: 3rem !default;
$h2-font-size: 2.5rem !default;
$h3-font-size: 2rem !default;
$text-muted: $gray-600 !default;

// Tables

$table-border-color: $gray-700 !default;

$table-bg-scale: 0 !default;

// Forms

$input-bg: $white !default;
$input-color: $gray-800 !default;
$input-border-color: $body-bg !default;
$input-group-addon-color: $gray-500 !default;
$input-group-addon-bg: $gray-700 !default;

$form-check-input-bg: $white !default;
$form-check-input-border: none !default;

$form-file-button-color: $white !default;

// Dropdowns

$dropdown-bg: $gray-900 !default;
$dropdown-border-color: $gray-700 !default;
$dropdown-divider-bg: $gray-700 !default;
$dropdown-link-color: $white !default;
$dropdown-link-hover-color: $white !default;
$dropdown-link-hover-bg: $primary !default;

// Navs

$nav-link-padding-x: 2rem !default;
$nav-link-disabled-color: $gray-500 !default;
$nav-tabs-border-color: $gray-700 !default;
$nav-tabs-link-hover-border-color: $nav-tabs-border-color $nav-tabs-border-color
  transparent !default;
$nav-tabs-link-active-color: $white !default;
$nav-tabs-link-active-border-color: $nav-tabs-border-color
  $nav-tabs-border-color transparent !default;

// Navbar

$navbar-padding-y: 1rem !default;
$navbar-dark-color: rgba($white, 0.6) !default;
$navbar-dark-hover-color: $white !default;
$navbar-light-color: rgba($gray-900, 0.7) !default;
$navbar-light-hover-color: $gray-900 !default;
$navbar-light-active-color: $gray-900 !default;
$navbar-light-toggler-border-color: rgba($gray-900, 0.1) !default;

// Pagination

$pagination-color: $white !default;
$pagination-bg: $success !default;
$pagination-border-width: 0 !default;
$pagination-border-color: transparent !default;
$pagination-hover-color: $white !default;
$pagination-hover-bg: lighten($success, 10%) !default;
$pagination-hover-border-color: transparent !default;
$pagination-active-bg: $pagination-hover-bg !default;
$pagination-active-border-color: transparent !default;
$pagination-disabled-color: $white !default;
$pagination-disabled-bg: darken($success, 15%) !default;
$pagination-disabled-border-color: transparent !default;

// Cards

$card-cap-bg: $gray-700 !default;
$card-bg: $gray-800 !default;

// Popovers

$popover-bg: $gray-800 !default;
$popover-header-bg: $gray-700 !default;

// Toasts

$toast-background-color: $gray-700 !default;
$toast-header-background-color: $gray-800 !default;

// Modals

$modal-content-bg: $gray-800 !default;
$modal-content-border-color: $gray-700 !default;
$modal-header-border-color: $gray-700 !default;

// Progress bars

$progress-bg: $gray-700 !default;

// List group

$list-group-color: $body-color !default;
$list-group-bg: $gray-800 !default;
$list-group-border-color: $gray-700 !default;
$list-group-hover-bg: $gray-700 !default;
$list-group-action-hover-color: $list-group-color !default;
$list-group-action-active-bg: $gray-900 !default;

// Breadcrumbs

$breadcrumb-padding-y: 0.375rem !default;
$breadcrumb-padding-x: 0.75rem !default;
$breadcrumb-bg: $gray-700 !default;
$breadcrumb-border-radius: 0.25rem !default;

// Close

$btn-close-color: $white !default;
$btn-close-opacity: 0.4 !default;
$btn-close-hover-opacity: 1 !default;

// Code

$pre-color: inherit !default;

$spacer: 0.66rem !default;
$card-spacer-y: $spacer;
$card-spacer-x: $spacer;
$grid-gutter-width: 20px !default;

// buttons
$btn-disabled-opacity: 0.5 !default;

// border
$kendo-input-border-width: 1px !default;
$kendo-button-border-width: 1px !default;
