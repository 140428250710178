.rta {
  position: relative;
  width: calc(100% - 42px);
  float: left;
}

.rta__loader.rta__loader--empty-suggestion-data {
  border-radius: $border-radius;
  padding: 5px;
}

.rta--loading {
  .rta__loader.rta__loader--suggestion-data {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
  .rta__loader.rta__loader--suggestion-data > * {
    position: relative;
    top: 50%;
  }
}

.rta__textarea {
  width: 100%;
  height: 100%;
  font-size: 1em;
}

.rta__autocomplete {
  max-width: 90%;
  position: absolute;
  display: block;
  margin-top: 1em;
  z-index: 999;
}

.rta__autocomplete--top {
  margin-top: 0;
  margin-bottom: 1em;
}

.rta__list {
  margin: 0;
  padding: 0;
  background: $body-bg;
  border: 1px solid $gray-600;
  border-radius: $border-radius;
  list-style: none;
}

.rta__entity {
  outline: none;
}

.rta__entity:hover {
  cursor: pointer;
  background-color: #375a7f;
}

.rta__item:not(:last-child) {
  border-bottom: 1px solid $gray-600;
}

.rta__entity > * {
  @extend .text-truncate;
  padding-left: 4px;
  padding-right: 4px;
}

.rta__entity--selected {
  color: white;
  text-decoration: none;
  background: #375a7f;
}
